import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col, Row, Alert, Upload, Progress, Modal } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import InputQuillEditor from '@uz/unitz-components-web/InputQuillEditor';
import InputText from '@uz/unitz-components-web/InputText';
import Link from '@uz/unitz-components-web/Link';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem, DatePicker, TimePicker, Select, Checkbox } from 'formik-antd';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { Image } from '@uz/unitz-components-web/Image';
import cx from 'classnames';
import AddStudentToCourseModal from '@uz/unitz-tool-components/AddStudentToCourseModal';
import CustomTable from '@uz/unitz-components-web/CustomTable';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const SESSION_START_TIME_FORMAT = 'HH:mm';

const View8 = () => {
  const [isVisible, $isVisible] = React.useState(false);
  const [isAssistantVisible, $isAssistantVisible] = React.useState(false);
  const [isStudentVisible, $isStudentVisible] = React.useState(false);

  const openModal = () => {
    $isVisible(true);
  };

  const hideModal = () => {
    $isVisible(false);
  };

  const openModalAssistant = () => {
    $isAssistantVisible(true);
  };

  const hideModalAssistant = () => {
    $isAssistantVisible(false);
  };

  const openModalStudent = () => {
    $isStudentVisible(true);
  };

  const hideModalStudent = () => {
    $isStudentVisible(false);
  };

  return (
    <DIV className="component">
      {ctx.debug(() => {
        ctx.set('form', ctx.apply('formStepsModel.getForm'));
      })}

      <DIV forceCtx>
        <FormProvider form={ctx.get('form')}>
          <Form {...layout} className="course-form">
            <Row>
              <Col className="formStatus" span={24}>
                {!!ctx.get('form.status.error') && (
                  <div className="py-2">
                    <Alert message={ctx.get('form.status.error.message')} type="error" />
                  </div>
                )}
                {!!ctx.get('form.status.success') && (
                  <div className="py-2">
                    <Alert message={ctx.get('form.status.success.message')} type="success" />
                  </div>
                )}
              </Col>
            </Row>
            {ctx.apply('formStepsModel.isStep', 0) && (
              <div className="step-content-3">
                <div className="text-sm text-sub mb-4 font-semibold">
                  {ctx.apply('i18n.t', 'ZoomToolCourse.Form.student')}{' '}
                  {!!ctx.get('form.values.students.length') && `(${ctx.get('form.values.students.length')})`}
                </div>

                <CustomTable
                  columns={ctx.get('tableStudentColumn.columns')}
                  dataSource={ctx.get('tableStudentColumn.dataSource', [])}
                  rowKey={(rec) => _.get(rec, 'id')}
                  pagination={false}
                  // loading={ctx.get('paginationModel.isLoading')}
                  // onChange={ctx.get('paginationModel.onChange')}
                  size="middle"
                />
                <Row className="my-6">
                  <Col span={6}>
                    <Button
                      type="outline"
                      className="mx-auto"
                      size="small"
                      block
                      icon={gstyles.icons({
                        name: 'add-circle',
                        size: 20,
                        fill: gstyles.colors.brandb500,
                        className: 'inline-block mx-2',
                      })}
                      onClick={openModalStudent}
                    >
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_student')}
                    </Button>
                    <Modal
                      width={1248}
                      footer={null}
                      className="rounded-lg overflow-hidden"
                      maskStyle={{ background: gstyles.colors.black300 }}
                      visible={isStudentVisible}
                      centered
                      bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                      wrapClassName={'my-modal'}
                      onCancel={hideModalStudent}
                    >
                      <AddStudentToCourseModal />
                    </Modal>
                  </Col>
                </Row>
              </div>
            )}
            <Row justify="end" className="mt-4 steps-action">
              <Col span={12}>
                <Row gutter={gutter}>
                  <Col span={12}>
                    {ctx.get('formStepsModel.current') === 0 ? (
                      <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                        <Button name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                          <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cancel')}</span>
                        </Button>
                      </Link>
                    ) : (
                      <Button name="ink" className="w-full" onClick={ctx.get('formStepsModel.prev')}>
                        <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.back')}</span>
                      </Button>
                    )}
                  </Col>
                  <Col span={12}>
                    {ctx.apply('formStepsModel.hasNext') && (
                      <Button
                        type="primary"
                        className="w-full"
                        loading={ctx.get('form.isSubmitting')}
                        onClick={ctx.get('form.handleSubmit')}
                        disabled={!ctx.get('form.canSubmit')}
                      >
                        <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.next')}</span>
                      </Button>
                    )}
                    {!!ctx.apply('formStepsModel.isLast') && (
                      <Button
                        type="primary"
                        className="w-full"
                        loading={ctx.get('form.isSubmitting')}
                        onClick={ctx.get('form.handleSubmit')}
                        disabled={!ctx.get('form.canSubmit')}
                      >
                        <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.done')}</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </DIV>
    </DIV>
  );
};

export default displayName(View8);
